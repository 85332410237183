/* eslint-disable */
import axios from "axios";
import { FLIGHT_TYPES, getEnrollmentId } from "../common/Utils";

// getting files from env
const BaseUrl = process.env.REACT_APP_BASEURL;

const headers = {
  headers: {
    "Content-Type": "application/json",
    Clientid: process.env.REACT_APP_CLIENTID,
  },
};

export const validateEnrollmentId = async (id) => {
  try {
    let requestBody = {
      enrollmentId: id,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/validation";

    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const getAirports = async () => {
  let enrollmentId = null
  try {
    enrollmentId = getEnrollmentId();
    if (!enrollmentId) {
      return { success: false, message: "no enrollment id found in local storage", is_reload: true };
    }
  } catch (error) {
    return { success: false, message: "no enrollment id found in local storage...", is_reload: true };
  }

  try {
    let requestBody = {
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/tl/airports";
    const response = await axios.post(url, requestBody, headers);
    return response.data.data.airports;
  } catch (error) {
    return [];
  }
};

export const getAirlines = async () => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

  try {
    let requestBody = {
      enrollmentId: enrollmentId,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/tl/airlines";
    const response = await axios.post(url, requestBody, headers);
    if (response.data.success) {
      return response.data.data.airlines;
    }
    return [];
  } catch (error) {
    return [];
  }
};

export const getSchedule = async (payload) => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));
  try {
    let requestBody = {
      enrollmentId: enrollmentId,
      ...payload,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/tl/flight/schedule";
    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false };
  }
};

export const getBookingRules = (airlineCode) => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

  let requestBody = {
    enrollmentId: enrollmentId,
    marketingAirline: airlineCode,
  };
  requestBody = JSON.stringify(requestBody);
  const url = BaseUrl + "/tl/flight/booking/rules";
  return axios.post(url, requestBody, headers);
};

export const getBookingAddOns = (journeyType, flightIds) => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

  let requestBody = {
    enrollmentId: enrollmentId,
    journeyType:
      journeyType === FLIGHT_TYPES.roundTrip ? "BASIC_RT" : FLIGHT_TYPES.oneWay,
    flightIds: flightIds,
  };
  requestBody = JSON.stringify(requestBody);
  const url = BaseUrl + "/tl/flight/booking/add-ons";
  return axios.post(url, requestBody, headers);
};

export const bookFlightTicket = (bookinDetails) => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

  let requestBody = {
    enrollmentId: enrollmentId,
    ...bookinDetails,
  };
  requestBody = JSON.stringify(requestBody);
  const url = BaseUrl + "/tl/flight/ticket/booking";
  return axios.post(url, requestBody, headers);
};

export const confirmBooking = async (payload) => {
  let enrollmentId = JSON.parse(localStorage.getItem("enrollmentId"));

  try {
    let requestBody = {
      enrollmentId: enrollmentId,
      ...payload,
    };
    requestBody = JSON.stringify(requestBody);
    const url = BaseUrl + "/tl/flight/ticket/booking/confirmation";
    const response = await axios.post(url, requestBody, headers);
    return response.data;
  } catch (error) {
    return { success: false };
  }
};
