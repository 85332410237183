import React from "react";
import { getFareWithoutDecimals, priceFormatter } from "../../common/Utils";
import { Typography } from "@mui/material";

export default function BaggageRadio({
  segment,
  passenger,
  selected,
  onChange,
}) {
  const baseBaggage = segment.baggageOptions.find(
    (bag) => parseInt(bag.priceWithCurrency.amount) === 0
  );
  const isBaggageActive = (option) => option.baggageWeight === selected;

  const getEle = () => {
    return (
      <div className="baggage-container">
        {segment.baggageOptions.map((option, index) => {
          let bagValLabel = `${option.baggageWeight}`;
          if (baseBaggage) {
            bagValLabel = `+${Math.abs(
              parseInt(option.baggageWeight) - parseInt(baseBaggage.baggageWeight)
            )}`;
          }
          return (
            <div
              key={index}
              className={`${
                isBaggageActive(option) ? "baggage-active" : ""
              } pointer baggage `}
              onClick={() => onChange(segment, passenger, option)}
            >
              <div className="bold"><Typography style={{fontWeight: "700"}}>{`${bagValLabel} ${option.baggageType}`}</Typography></div>
              <div><Typography>{`Rp ${priceFormatter(
                getFareWithoutDecimals(option.priceWithCurrency.amount)
              )}`}</Typography></div>
            </div>
          );
        })}
      </div>
    );
  };

  return <>{getEle()}</>;
}
