import React from "react";
import { Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export const DropdownSliderInput = ({
  value = "",
  className,
  error,
  errorMessage,
  onClickHandler,
  label,
  placeholder = "",
}) => {
  return (
    <div>
      <Typography
        className={"inputLabels"}
        children={label}
        id="StasiunAsal-label"
      />
      <div
        onClick={onClickHandler}
        className={className}
        id="StasiunAsal-value"
      >
        <Typography
          children={value ? value : placeholder}
          className={value ? "selectInputDataFont" : "selectInputPlaceholder"}
          id="StasiunAsal-name"
        />
        <KeyboardArrowDownIcon className={"iconArrowInSelectInput"} />
      </div>
      {error && (
        <Typography style={{ color: "#d32f2f", fontSize: "12px", fontWeight: "500" }}>
          {errorMessage}
        </Typography>
      )}
    </div>
  );
};
