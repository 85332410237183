/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { countryData } from "../../common/Country";
import SelectDropdown from "../../CommonComponents/SelectDropdown/SelectDropdown";
import { salutationList } from "../../common/Utils";
import TextInput from "../../CommonComponents/TextInput/TextInput";

const ContactDetails = ({ submitError, setContactError }) => {
  const [openContactDetails, setOpenContactDetails] = useState(false);
  const [salutation, setSalutation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneCode, setPhoneCode] = useState("62");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");

  const [titleFocus, setTitleFocus] = useState(false);
  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const [error, setError] = useState(false);
  const [countryCodeOpen, setCountryCodeOpen] = useState(false);
  const [inputPhoneCode, setInputPhoneCode] = useState("+62");
  const [countryList, setCountryList] = useState([]);

  const handleSubmit = () => {
    if (inputPhoneCode.slice(1) != phoneCode) {
      setInputPhoneCode(`+${phoneCode}`);
    }
    if (
      salutation !== "" &&
      firstName.length > 1 &&
      phoneCode !== "" &&
      phoneCode > 0 &&
      phoneNo !== "" &&
      phoneNo.length >= 8 &&
      phoneNo > 0 &&
      email != "" &&
      emailRegex.test(email)
    ) {
      setError(false);
      setContactError(false);
      let KAI = JSON.parse(localStorage.getItem("KAI"));
      KAI = KAI != null ? KAI : {};
      KAI.customerContactDetails = {
        salutation,
        firstName,
        lastName,
        phoneCode,
        phoneNo,
        email,
      };
      localStorage.setItem("KAI", JSON.stringify(KAI));
      setOpenContactDetails(false);
    } else setError(true);
    setCountryCodeOpen(false);
  };

  useEffect(() => {
    if (submitError) {
      if (
        !salutation ||
        salutation == "" ||
        firstName.length < 2 ||
        phoneCode == "" ||
        phoneNo == "" ||
        phoneNo.length < 8 ||
        email == ""
      ) {
        setError(true);
        setOpenContactDetails(true);
      }
    }
  }, [submitError]);

  useEffect(() => {
    let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")));
    p.then((res) => {
      if (res?.customerContactDetails) {
        setSalutation(res.customerContactDetails.salutation);
        setFirstName(res.customerContactDetails.firstName);
        setLastName(res.customerContactDetails.lastName);
        setPhoneCode(res.customerContactDetails.phoneCode);
        setPhoneNo(res.customerContactDetails.phoneNo);
        setEmail(res.customerContactDetails.email);
      } else console.log("contact details not found");
    });
  }, []);

  useEffect(() => {
    if (inputPhoneCode != "") {
      let actualPhoneCode = "";
      if (inputPhoneCode.slice(0, 1) == "+") {
        actualPhoneCode = inputPhoneCode.slice(1);
      } else {
        actualPhoneCode = inputPhoneCode;
      }
      let sortedCountryList = [];
      for (const element of countryData) {
        let isCountryName = element.name
          .toLowerCase()
          .includes(actualPhoneCode.toLocaleLowerCase());
        let isCountryPhone = element.phone.includes(
          actualPhoneCode.toLocaleLowerCase()
        );
        if (isCountryName || isCountryPhone) {
          sortedCountryList.push(element);
        }
      }
      setCountryList([...sortedCountryList]);
    }
    if (inputPhoneCode == "" || inputPhoneCode == "+") {
      setCountryList([...countryData]);
      setPhoneCode("");
    }
  }, [inputPhoneCode]);

  return (
    <div id={"contact-details"} style={{ marginTop: "24px" }}>
      <Typography
        children={"Data Pemesan (Untuk E - Ticket)"}
        className={"inputSectionLabels"}
      />

      {/* FILL THE CONTACT DETAILS */}
      <Accordion
        expanded={openContactDetails}
        onChange={() => setOpenContactDetails(!openContactDetails)}
        id={"dataPemesan-dropdown"}
        style={{ marginTop: "16px" }}
        className={error || submitError ? "dropShadowError" : "dropShadow"}
      >
        <AccordionSummary>
          {(salutation === "" && firstName === "" && lastName === "") ||
          phoneCode == "" ||
          phoneNo == "" ||
          email == "" ||
          openContactDetails ? (
            <Grid container>
              <Grid item xs={9}>
                {" "}
                <Typography
                  className={
                    error || submitError
                      ? "inputSecondarySectionLabelsError"
                      : "inputSecondarySectionLabels"
                  }
                >
                  Isi Data Pemesan
                  <span style={{ color: "red", fontSize: "bold" }}>*</span>
                </Typography>
              </Grid>
              {!openContactDetails &&
                JSON.parse(localStorage.getItem("KAI"))
                  ?.customerContactDetails == null && (
                  <Grid item style={{ left: "5%", position: "relative" }}>
                    <Grid container>
                      <Grid
                        item
                        style={{
                          position: "relative",
                          top: "3px",
                          marginRight: "3px",
                        }}
                      >
                        {" "}
                        <AddIcon
                          style={{
                            color: "#0047A9",
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        />{" "}
                      </Grid>
                      <Grid item>
                        {" "}
                        <Typography
                          children={"Isi Data"}
                          style={{
                            fontSize: "14px",
                            color: "#0047A9",
                            fontWeight: "bold",
                          }}
                        />{" "}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography
                  children={firstName + " " + lastName}
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    color: "#1A1A1A",
                  }}
                />
                <Typography
                  children={email}
                  style={{ fontSize: "12px", color: "#666", marginTop: "5px" }}
                />
                <Typography
                  children={`+${phoneCode} ${phoneNo}`}
                  style={{ fontSize: "12px", color: "#666" }}
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon
                  style={{
                    fontSize: "18px",
                    marginRight: "2px",
                    color: "#5CD182",
                  }}
                />
              </div>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails style={{ paddingTop: "0" }}>
          <Divider style={{ marginBottom: "16px" }} />
          <SelectDropdown
            error={error && (salutation === "" || !salutation)}
            value={salutation}
            label="Sapaan"
            isFocused={titleFocus}
            onChange={(e) => {
              setSalutation(e.target.value);
            }}
            onFocus={() => setTitleFocus(true)}
            onBlur={() => setTitleFocus(false)}
            errorMessage={
              (salutation === "" || !salutation) && error
                ? "Sapaan tidak boleh kosong"
                : ""
            }
            menuItems={salutationList}
          />

          {/* CONTACT PERSON NAME */}
          <TextInput
            value={firstName}
            label={"Nama Depan"}
            error={error && firstName.length < 2}
            onFocus={() => setFirstNameFocus(true)}
            onChange={(e) => {
              if (
                e.target.value.length <= 20 &&
                /^[a-zA-Z ]*$/.test(e.target.value)
              )
                setFirstName(e.target.value);
            }}
            onBlur={() => setFirstNameFocus(false)}
            errorMessage={
              firstName.length < 2 && error
                ? "Nama depan tidak boleh kosong"
                : ""
            }
            isFocused={firstNameFocus}
          />

          <TextInput
            label="Nama Belakang"
            value={lastName}
            onFocus={() => setLastNameFocus(true)}
            onBlur={() => setLastNameFocus(false)}
            onChange={(e) => {
              if (
                e.target.value.length <= 20 &&
                /^[a-zA-Z ]*$/.test(e.target.value)
              )
                setLastName(e.target.value);
            }}
            isFocused={lastNameFocus}
          />

          {/* PHONE INFO */}
          <Grid container spacing={1}>
            {/* PHONE CODE */}
            <Grid item xs={5}>
              <TextInput
                label="Kode Negara"
                error={!phoneCode && error}
                onFocus={() => setCountryCodeOpen(true)}
                value={inputPhoneCode}
                errorMessage={!phoneCode && error ? "Wajib diisi" : ""}
                onChange={(e) => {
                  setInputPhoneCode(e.target.value);
                }}
              />
              {countryCodeOpen &&
                !titleFocus &&
                !firstNameFocus &&
                !lastNameFocus &&
                !phoneFocus &&
                !emailFocus && (
                  <div className="dropdownBox">
                    {countryList.map((item, i) => (
                      <div
                        key={i}
                        className={"phoneCodeListItem"}
                        onClick={() => {
                          setInputPhoneCode(`+${item.phone}`);
                          setPhoneCode(item.phone);
                          setCountryCodeOpen(false);
                        }}
                      >
                        <Typography
                          type="text"
                          children={`${item.name} (+${item.phone})`}
                        />
                      </div>
                    ))}
                  </div>
                )}
            </Grid>
            {/* PHONE NUMBER */}
            <Grid item xs={7}>
              <TextInput
                type={"number"}
                error={
                  error && (phoneNo < 0 || phoneNo == "" || phoneNo.length < 8)
                }
                value={phoneNo}
                label="Nomor Handphone"
                onFocus={() => setPhoneFocus(true)}
                onBlur={() => setPhoneFocus(false)}
                onChange={(e) => {
                  setPhoneNo(e.target.value);
                }}
                errorMessage={
                  (phoneNo == "" ||
                    phoneNo < 0 ||
                    phoneNo?.length < 8) &&
                  error
                    ? "Wajib diisi"
                    : ""
                }
              />
            </Grid>
          </Grid>

          {/* ----EMAIL INFO---- */}
          <TextInput 
          error={(error && email == "") || (error && !emailRegex.test(email))}
          value={email}
          label={"Email"}
          onFocus={() => setEmailFocus(true)}
          onBlur={() => setEmailFocus(false)}
          onChange={(e) => setEmail(e.target.value)}
          errorMessage={
            (email == "" && error) || (error && !emailRegex.test(email))
              ? "Format email tidak sesuai"
              : ""
          }
          />
          <Typography
            children={"Contoh: email@example.com"}
            style={{ fontSize: "11px", color: "#979797" }}
          />

          <Button
            children={"Simpan"}
            className={"submitButton"}
            fullWidth
            onClick={() => handleSubmit()}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ContactDetails;
