/* eslint-disable */
import React from 'react';

import { Button, Paper, Slide, Typography } from '@mui/material';


const FailedPopUp = ({ slideOpen, handleCancleConfirmBooking}) => {
    
    return (
        <div style={{position: 'fixed', top:"0", bottom:"0", right:"0", left:"0", background:"#80808078", display: slideOpen ? "" : "none"}} >
        <div style={{position:"absolute", bottom:"0", right:"0", left:"0", minHeight: "260px", margin: "auto", maxWidth:"640px", borderRadius:"16px 16px 0 0", background:"#FFF", display: slideOpen ? "" : "none"}}>
            <Slide direction={"up"} in={slideOpen} className={"sliderStationList"} id='slider-container' >
                <Paper id="slider-paper" style={{height: slideOpen ? "100%" : "0%"}} >
                    <div style={{padding:"16px", paddingTop:"0"}} >

                        

                        <div className={"selectStationSliderHeader"}>
                            <Typography children={"Pemesanan tidak berhasil"} className='popUpHeaderText' id="headerText" />
                        </div>

                        <div className={"stationlistContainer"}>
                            <Typography>Silakan lakukan pemesanan ulang.</Typography>
                        </div>

                        <Button children={"Coba Lagi"} fullWidth variant={"text"} className={"submitButton"} onClick={()=>{handleCancleConfirmBooking()}} id="CariTiketPesawat"/>
                        
                    </div>
                </Paper>
            </Slide>
        </div>
        </div>
    )
}

export default FailedPopUp;