/* eslint-disable */
import React from "react";
import "../../Styles/Spinner.css";

import { Dialog, Typography } from "@mui/material";

const RedirectPage = () => {
  return (
    <Dialog
      open={true}
      onClose={() => { }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"sm"}
    >
      <div className="redirectPageText">
        <div className="loadingio-spinner-spin-yq8n5svaih"><div className="ldio-dhj58xzoplv">
          <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
        </div></div>
      </div>
      <Typography style={{ fontWeight: 'bold' }} className="redirectPageText">Mohon Tunggu, kami sedang</Typography>
      <Typography style={{ fontWeight: 'bold' }} id="redirectPageText" className="redirectPageText">
        memproses tiket Anda.</Typography>
      {/* <Typography className="redirectPageText" sx={{ fontSize: '0.90rem' }}>Proses ini akan memakan waktu kurang</Typography>
      <Typography className="redirectPageText" sx={{ marginBottom: '20px', fontSize: '0.90rem' }}>dari 1 menit</Typography> */}
    </Dialog>
  );
};

export default RedirectPage;
