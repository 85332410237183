/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { Header, ContactDetails, PassengerInfo, Loader } from '../index';
import { FLIGHT_TYPES, getDateInFormat, getDurationLabel, getTotalFare, isDocCheckValid, isEmptyObj, parseFloatToDecimals, priceFormatter } from '../../common/Utils';
import { getBookingRules, getBookingAddOns, bookFlightTicket, confirmBooking } from '../../api/ApiUtils';
import useLocalStorage, { APP_LOCAL_STORAGE_KEYS } from '../../hooks/useLocalstorage';
import { JOURNEY_TYPE } from '../StationSelection/StationSelection';
import { PopUpModal } from '../../CommonComponents/PopUp/PopUpModal';
import PlainTicketCard from '../PlainTicketCard/PlainTicketCard';
import ExtraFacilities from '../ExtraFacilities/ExtraFacilities';
import moment from 'moment';
import RedirectPage from '../../CommonComponents/RedirectPage/RedirectPage';
import "../../Styles/InputData.css";
import ConfirmPopUp from './ConfirmationPopUp';
import LoaderComponent from './LoaderComponent';
import FailedPopUp from './FailedPopUp';
import { calculateTotalBaggagePrice, getPassengerAddonsDetails } from '../ExtraFacilities/BaggageService';

const StaticUrl = process.env.REACT_APP_STATIC_URL;

const InputData = () => {
    const header = "Isi Data";
    const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
    const [loading, setLoading] = useState(false);
    const [passengerError, setPassengerError] = useState(false);
    const [contactError, setContactError] = useState(false);
    const [openBagageSlider, setOpenBagageSlider] = useState(false);
    const [isLoadingDialogue, showLoadingDialogue] = useState(false);
    const [documentType, setDocumentType] = useState('');
    const { INITIAL_FORM_DATA } = localStorage;
    const flightDetails = JSON.parse(INITIAL_FORM_DATA);
    const [baggageOptions, setBaggageOptions] = useState();
    const divRef = useRef(null);
    const [totalTripFare, setTotalTripFare] = useState(0);
    const [flightIdsArray, setFlightIds] = useState([]);
    const [confirmBookingPopUpOpen, setConfirmBookingPopUpOpen] = useState(false);
    const [confirmBookingInfo, setBookingInfo] = useState({});
    const { get: getLocalStorageValue } = useLocalStorage();
    const [docRequireForDomestic, setDocRequireForDomestic] = useState(false);
    const [loadingPopUp, setLoadingPopUp] = useState(false);
    const [failedStatePopUp, setFailedStatePopUp] = useState(false);
    const [baggageSelectionData, setBaggageSelectionData] = useState({});
    const [showBaggageResetMsg, updateShowBaggageResetMsg] = useState(false);

    const flightDataOneWay = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsOneWay);
    const flightDataRound = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.selectedFlightDetailsRound);

    function getFlightData() {
        const flights = {};
        const airlines = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.airlines);
        const airportsByCode = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.airportsByCode);
        const initialFormData = getLocalStorageValue(APP_LOCAL_STORAGE_KEYS.initialFormData);

        if (isEmptyObj(flightDataOneWay)) {
            throw new Error("Selected flight details is empty");
        }
        const adultCount = initialFormData.passengers.adult;
        const childCount = initialFormData.passengers.child;
        const infantCount = initialFormData.passengers.infant;


        const seatClass = flightDataOneWay.journeys[0].segments[0].seatClass;
        const { logoUrl: airlineLogo, airlineName } = airlines[flightDataOneWay.journeys[0].segments[0].operatingAirline];
        const airlineCode = flightDataOneWay.journeys[0].segments[0].marketingAirline;
        const flightCode = flightDataOneWay.journeys[0].segments[0].flightCode;
        const journeyDuration = getDurationLabel(flightDataOneWay.journeys[0].journeyDuration);
        const depDay = getDateInFormat(flightDataOneWay.journeys[0].departureDetail.departureDate, "ddd");
        const depDate = flightDataOneWay.journeys[0].departureDetail.departureDate;
        const depTime = flightDataOneWay.journeys[0].departureDetail.departureTime;
        const depArrTime = flightDataOneWay.journeys[0].arrivalDetail.arrivalTime;
        const depAirportCode = flightDataOneWay.departureAirport;
        const depArrAirportCode = flightDataOneWay.arrivalAirport;
        const depTerminal = flightDataOneWay.journeys[0].departureDetail.departureTerminal;
        const depCity = airportsByCode[flightDataOneWay.journeys[0].departureDetail.airportCode].city;

        const depAdultFare = parseFloatToDecimals(flightDataOneWay.journeys[0].fareInfo.partnerFare.adultFare.totalFareWithCurrency.amount || 0);
        const depChildFare = parseFloatToDecimals(flightDataOneWay.journeys[0].fareInfo.partnerFare.childFare.totalFareWithCurrency.amount || 0);
        const depInfantFare = parseFloatToDecimals(flightDataOneWay.journeys[0].fareInfo.partnerFare?.infantFare?.totalFareWithCurrency.amount || 0);
        const depTotalFareRaw = parseFloatToDecimals(getTotalFare({
            adultFare: depAdultFare,
            childFare: depChildFare,
            infantFare: depInfantFare,
            adultCount,
            childCount,
            infantCount
        }));

        const depFlightId = flightDataOneWay.flightId;

        const depFlightDetails = {
            airlineCode: airlineCode,
            airlineLogo: airlineLogo,
            airlineName: airlineName,
            seatClass: seatClass,
            flightCode: flightCode,
            journeyDuration: journeyDuration,
            totalFare: priceFormatter(depTotalFareRaw),
            totalFareRaw: depTotalFareRaw,
            day: depDay,
            date: depDate,
            depTime: depTime,
            arrTime: depArrTime,
            depAirportCode: depAirportCode,
            arrAirportCode: depArrAirportCode,
            terminal: depTerminal,
            city: depCity,
            flightId: depFlightId
        }

        flights["dep"] = depFlightDetails;

        const isRoundTrip = initialFormData.journeyType === JOURNEY_TYPE.RoundTrip;

        if (isRoundTrip) {
            const retSeatClass = flightDataRound?.journeys[0]?.segments[0]?.seatClass;
            const { logoUrl: retAirlineLogo, airlineName: retAirlineName } = airlines[flightDataRound?.journeys[0]?.segments[0]?.operatingAirline] != null ? airlines[flightDataRound?.journeys[0]?.segments[0]?.operatingAirline] : { retAirlineLogo: '', retAirlineName: '' };
            const retAirlineCode = flightDataRound?.journeys[0]?.segments[0]?.marketingAirline;
            const retFlightCode = flightDataRound?.journeys[0].segments[0].flightCode;
            const retJourneyDuration = getDurationLabel(flightDataRound?.journeys[0]?.journeyDuration);

            const arrDay = getDateInFormat(flightDataRound?.journeys[0].arrivalDetail.arrivalDate, "ddd");
            const arrDate = flightDataRound?.journeys[0].arrivalDetail.arrivalDate;
            const arrDepTime = flightDataRound?.journeys[0].departureDetail.departureTime;
            const arrTime = flightDataRound?.journeys[0].arrivalDetail.arrivalTime;
            const arrAirportCode = flightDataRound?.arrivalAirport;
            const arrDepAirportCode = flightDataRound?.departureAirport;

            const arrTerminal = flightDataRound?.journeys[0].arrivalDetail.arrivalTerminal;
            const arrCity = airportsByCode[flightDataRound?.journeys[0].arrivalDetail.airportCode]?.city;

            const arrAdultFare = parseFloatToDecimals(flightDataRound?.journeys[0].fareInfo.partnerFare.adultFare.totalFareWithCurrency.amount || 0);
            const arrChildFare = parseFloatToDecimals(flightDataRound?.journeys[0].fareInfo.partnerFare.childFare.totalFareWithCurrency.amount || 0);
            const arrInfantFare = parseFloatToDecimals(flightDataRound?.journeys[0].fareInfo.partnerFare.infantFare.totalFareWithCurrency.amount || 0);
            const arrTotalFareRaw = parseFloatToDecimals(getTotalFare({
                adultFare: arrAdultFare,
                childFare: arrChildFare,
                infantFare: arrInfantFare,
                adultCount,
                childCount,
                infantCount
            }));
            const retFlightId = flightDataRound.flightId;

            const retFlightDetails = {
                airlineCode: retAirlineCode,
                airlineLogo: retAirlineLogo,
                airlineName: retAirlineName,
                seatClass: retSeatClass,
                flightCode: retFlightCode,
                journeyDuration: retJourneyDuration,
                totalFare: priceFormatter(arrTotalFareRaw),
                totalFareRaw: arrTotalFareRaw,
                day: arrDay,
                date: arrDate,
                depTime: arrDepTime,
                arrTime: arrTime,
                depAirportCode: arrDepAirportCode,
                arrAirportCode: arrAirportCode,
                terminal: arrTerminal,
                city: arrCity,
                flightId: retFlightId
            }

            flights["ret"] = retFlightDetails;

        }

        return flights;
    }

    const flightData = getFlightData();


    useEffect(() => {
        const airlineCode = flightData.dep.airlineCode;
        const airlineCodeRet = flightData?.ret?.airlineCode;
        let airlineCodeArray = [airlineCode]

        if (airlineCode && airlineCodeRet && airlineCode !== airlineCodeRet) {
            airlineCodeArray.push(airlineCodeRet)
        }
        const journeyType = flightDetails.journeyType;
        const flightIds = [flightData?.dep?.flightId];
        if (flightData?.ret?.flightId) {
            flightIds.push(flightData?.ret?.flightId);
        }
        setFlightIds(flightIds);
        setLoading(true);
        let bookingRulesPromises = [];

        bookingRulesPromises = airlineCodeArray.map((airlineCode) => {
            return getBookingRules(airlineCode);
        })

        const addOnServicePromis = getBookingAddOns(journeyType, flightIds)
        const arrayPromises = bookingRulesPromises.concat([addOnServicePromis]);
        Promise.all(arrayPromises).then((resp) => {
            setLoading(false);
            if (bookingRulesPromises.length > 1) {
                if (resp[0]?.data?.success && resp[1]?.data?.success) {
                    if (initialFormData.arrivalAirportDetails.countryCode === initialFormData.departureAirportDetails.countryCode) {
                        if (resp[0]?.data?.data?.requiresId === true || resp[1]?.data?.data?.requiresId === true) {
                            setDocumentType('NATIONAL_ID');
                        } else {
                            setDocumentType('NODOC')
                        }
                        if (resp[0]?.data?.data?.requiresDocumentNoForDomestic === true || resp[1]?.data?.data?.requiresDocumentNoForDomestic === true) {
                            setDocRequireForDomestic(true)
                        }
                    } else {
                        if (resp[0]?.data?.data?.requiresDocumentNoForInternational === true || resp[1]?.data?.data?.requiresDocumentNoForInternational === true) {
                            setDocumentType('PASSPORT');
                        } else {
                            setDocumentType('NODOC')
                        }
                    }
                }
                if (resp[2]?.data?.success) {
                    setBaggageOptions(resp[2]?.data?.data?.journeysWithAvailableAddOnsOptions);
                }
                else {
                    showErrorPopUp({ showPopUp: true, message: resp[0]?.data?.message || resp[1]?.data?.message || "Something went wrong! Please try again." });
                }
            } else {
                if (resp[0]?.data?.success) {
                    if (initialFormData.arrivalAirportDetails.countryCode === initialFormData.departureAirportDetails.countryCode) {
                        if (resp[0]?.data?.data?.requiresId === true) {
                            setDocumentType('NATIONAL_ID')
                        } else {
                            setDocumentType('NODOC')
                        }
                        if (resp[0]?.data?.data?.requiresDocumentNoForDomestic === true) {
                            setDocRequireForDomestic(true)
                        }
                    } else {
                        if (resp[0]?.data?.data?.requiresDocumentNoForInternational === true) {
                            setDocumentType('PASSPORT')
                        } else {
                            setDocumentType('NODOC')
                        }
                    }
                } else {
                    showErrorPopUp({ showPopUp: true, message: resp[0]?.data?.message });
                }
                if (resp[1]?.data?.success) {
                    setBaggageOptions(resp[1]?.data?.data?.journeysWithAvailableAddOnsOptions);
                } else {
                    showErrorPopUp({ showPopUp: true, message: "Maaf, maskapai ini tidak menyediakan tambahan bagasi" });
                }
            }
        })
            .catch((err) => {
                setLoading(false);
                if (err.message) {
                    showErrorPopUp({ showPopUp: true, message: err.message });
                } else {
                    showErrorPopUp({ showPopUp: true, message: 'Some error occurred!!' });
                }
            })
    }, [])

    useEffect(() => {
        if (contactError) {
            setTimeout(() => {
                setContactError(false)
            }, 3000);
        }
        if (passengerError) {
            setTimeout(() => {
                setPassengerError(false);
            }, 3000);
        }
    }, [contactError, passengerError]);

    const validateNumberId = (passenger) => {
        let isValidated = true;
        if (passenger.passengerDocumentType === "NODOC") {
            return true;
        }
        if (passenger.passengerDocumentType === "NATIONAL_ID") {
            isValidated = passenger.passengerNumberID !== "";
        } else {
            isValidated = passenger.passportNo !== "" && passenger.passportIssuingCountry !== "" && passenger.passportExpirationDate !== "";
        }
        return isValidated;
    }

    const passengerValidationCheck = (passengerList) => {
        let passengerListCheck = true;
        for (const element of passengerList) {
            passengerListCheck = (
                element.pFirstName !== '' && (element.lastName !== '' || element.noLastName) && element.salutation !== ''
                && element.dateOfBirth !== '' && element.dateOfBirth !== null && element.citizenship !== '' && validateNumberId(element)
            );
            if (!passengerListCheck) return passengerListCheck;
        }
        return passengerListCheck;
    }

    const handlePesanSubmit = () => {
        let p = Promise.resolve(JSON.parse(localStorage.getItem("KAI")))
        console.log({ p });
        p.then(async response => {
            if (response?.passengerList && response?.customerContactDetails) {
                let { passengerList, customerContactDetails } = response;


                // check for customerContactDetails contains all the data.
                let { firstName, lastName, phoneCode, phoneNo, email, salutation } = customerContactDetails;
                let customerContactDetailsCheck = (firstName.length >= 2 && phoneCode !== "" && phoneNo !== "" && phoneNo.length >= 8 && salutation !== "" && email !== "");


                // min no.of passenger is 1 so there will be always atleast 1 passenger.
                // check for passengerList contains all the data.

                let passengerListCheck = passengerValidationCheck(passengerList);

                let isPassemgerValid = isDocCheckValid(passengerList)

                if (isPassemgerValid.error) {
                    showErrorPopUp({ showPopUp: true, message: isPassemgerValid.message });
                    return;
                }


                if (passengerListCheck && customerContactDetailsCheck) {
                    setPassengerError(false);
                    setContactError(false);
                    let newContactdetails = {
                        contactDetail: {
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            phoneNumber: phoneNo,
                            phoneNumberCountryCode: phoneCode,
                            customerEmail: email,
                            customerPhoneNumber: phoneNo,
                            customerPhoneNumberCountryCode: phoneCode
                        }
                    }
                    let newPassengerList = {
                        passengers: {
                            adults: [],
                            children: [],
                            infants: []
                        }
                    }

                    passengerList.forEach((p, index) => {
                        const { citizenship, lastName, numberID, pFirstName, passengerDocumentType,
                            passportIssuingCountry, passportNo, salutation, passportExpirationDate, dateOfBirth } = p;
                        let key = 'adults';
                        if (p.passenger === 'child') {
                            key = 'children';
                        } else if (p.passenger === 'infant') {
                            key = 'infants';
                        }
                        newPassengerList.passengers[key].push({
                            title: salutation,
                            firstName: pFirstName,
                            lastName: lastName,
                            gender: salutation == 'MR' ? 'M' : 'F',
                            dateOfBirth: moment(dateOfBirth).format("MM-DD-YYYY"),
                            documentDetail: (passengerDocumentType == "NATIONAL_ID" || passengerDocumentType == "PASSPORT") ? {
                                issuingCountry: passportIssuingCountry ? passportIssuingCountry : '',
                                documentNo: passengerDocumentType === 'PASSPORT' ? passportNo : numberID,
                                expirationDate: passportExpirationDate ? moment(passportExpirationDate).format("MM-DD-YYYY") : '',
                                documentType: passengerDocumentType
                            } : null,
                            nationality: citizenship,
                            birthLocation: citizenship,
                            addOns: getPassengerAddonsDetails(p, baggageSelectionData, baggageOptions, flightDataOneWay, flightDataRound)
                        })
                    });

                    const bookingDetails = {
                        ...newContactdetails,
                        ...newPassengerList,
                        flightIds: flightIdsArray,
                        journeyType: flightDetails.journeyType === FLIGHT_TYPES.roundTrip ? "BASIC_RT" : FLIGHT_TYPES.oneWay,
                        locale: "id_ID",
                        username: null,
                        loginID: email,
                        loginType: "EMAIL",
                        customerLoginID: email,
                        customerLoginType: "EMAIL",
                        departureDate: moment(flightDetails.depDate).format("MM-DD-YYYY"),
                        departureTime: flightData?.dep?.depTime
                    }
                    console.log("booking details -- ", bookingDetails)

                    setLoadingPopUp(true);
                    handleFlightBooking(bookingDetails);
                }
                else {
                    if (!passengerListCheck) {
                        setPassengerError(true)
                    }
                    if (!customerContactDetailsCheck) {
                        setContactError(true);
                    }
                }
            }
            else {
                if (!(response?.passengerList) || !(passengerValidationCheck(response?.passengerList))) {
                    setPassengerError(true)
                }
                if (!response?.customerContactDetails) {
                    setContactError(true);
                }
            }

        })

    }

    const handleFlightBooking = (bookingDetails) => {
        bookFlightTicket(bookingDetails).then(async (response) => {
            let bookingResponse = response.data;
            if (bookingResponse.success && bookingResponse.data) {
                if (bookingResponse.data.flightBookingDetail.grandTotalFareWithCurrency.amount != totalTripFare) {
                    setLoadingPopUp(false);
                    setConfirmBookingPopUpOpen(true);
                    setBookingInfo({ bookingId: bookingResponse.data.bookingId, newTotalTripFare: bookingResponse.data.flightBookingDetail.grandTotalFareWithCurrency.amount })
                } else {
                    const req = {
                        bookingId: bookingResponse.data.bookingId
                    }
                    const response = await confirmBooking(req);
                    setLoadingPopUp(false);
                    if (response?.success) {
                        showLoadingDialogue(true)
                    } else {
                        showErrorPopUp({ showPopUp: true, message: response.message });
                    }
                }
            } else if (bookingResponse.responseCode === "299") {
                handleFlightBooking(bookingDetails);
            } else {
                setLoadingPopUp(false);
                setFailedStatePopUp(true);
            }


        })
            .catch((err) => {
                setLoadingPopUp(false);
                showErrorPopUp({ showPopUp: true, message: 'Some error occurred!!' });
            })
    }


    const handlePopUpClose = () => {
        showErrorPopUp({ showPopUp: false });
    }

    const initialFormData = getLocalStorageValue("INITIAL_FORM_DATA");

    const passengerDetails = getLocalStorageValue("KAI");

    const handleConfirmBooking = async () => {
        const req = {
            bookingId: confirmBookingInfo.bookingId
        }
        setLoading(true);
        const response = await confirmBooking(req);
        setConfirmBookingPopUpOpen(false);
        if (response?.success) {
            setLoading(false);
            showLoadingDialogue(true)
        } else {
            setLoading(false);
            showErrorPopUp({ showPopUp: true, message: response.message });
        }
    }

    const handleCancleConfirmBooking = async () => {
        const req = {
            bookingId: "999999999999"
        }
        setLoading(true);
        await confirmBooking(req);
        setConfirmBookingPopUpOpen(false);
        window.parent.location = StaticUrl;
        window.parent.location.reload();
    }

    useEffect(() => {
        const tripFare = Object.values(flightData).reduce((acc, flight) => acc + flight.totalFareRaw, 0);
        const totalBaggagePrice = calculateTotalBaggagePrice(baggageSelectionData);
        setTotalTripFare(tripFare + totalBaggagePrice);
    }, [flightData, baggageSelectionData]);

    useEffect(() => {
        if (!isEmptyObj(baggageSelectionData)) {
            updateShowBaggageResetMsg(false);
        }
    }, [baggageSelectionData]);

    const resetBaggageSelectionData = () => {
        if (!isEmptyObj(baggageSelectionData)) {
            updateShowBaggageResetMsg(true);
        }
        setBaggageSelectionData({});
    }
    const baggagePrice = calculateTotalBaggagePrice(baggageSelectionData);

    return (
        <div id={"InputData"} ref={divRef}>
            <Loader loading={loading} />
            <PopUpModal open={errorPopUp.showPopUp} error={true} message={errorPopUp.message} handlePopUpClose={handlePopUpClose} />
            <Header header={header} back={'/stationpricecard'} />
            <div id={"InputDataForm"} style={{ padding: "16px", background: "" }} >
                <PlainTicketCard flightData={flightData} passengerClass={initialFormData.seatClass} />

                <ContactDetails submitError={contactError} setContactError={setContactError} />

                <PassengerInfo
                    documentType={documentType}
                    docRequireForDomestic={docRequireForDomestic}
                    ticiketInfo={{
                        totalPassengers: (flightDetails?.passengers?.adult + flightDetails?.passengers?.child + flightDetails?.passengers?.infant),
                        childPassengers: flightDetails?.passengers?.child, adultPassengers: flightDetails?.passengers?.adult, infantPassengers: flightDetails?.passengers?.infant
                    }}
                    submitError={passengerError}
                    initialFormData={initialFormData}
                    resetBaggageSelectionData={resetBaggageSelectionData}
                />

                <ExtraFacilities
                    passengerDetails={passengerDetails?.passengerList ?? []}
                    showErrorPopUp={showErrorPopUp}
                    showBaggageResetMsg={showBaggageResetMsg}
                    baggageOptions={baggageOptions}
                    bottomButtonRef={divRef}
                    setOpenBagageSlider={setOpenBagageSlider}
                    openBagageSlider={openBagageSlider}
                    baggageSelectionData={baggageSelectionData}
                    setBaggageSelectionData={setBaggageSelectionData}
                />
                <br />
                <Typography children={"Detail Pembayaran"} className={"inputSectionLabels"} />

                <PricingDetails flight={flightData} flightDataOneWay={flightDataOneWay} flightDataRound={flightDataRound} initialFormData={initialFormData} totalFare={totalTripFare} baggagePrice={baggagePrice} />

                <Divider style={{ marginTop: '20px' }} />
                <Grid container>
                    <Grid item xs={8} style={{ marginTop: '28px' }}>
                        <Typography id="GRATIS" children={"Total"} style={{ fontSize: "10px", color: "#1A1A1A" }} />
                        <Typography id="GRATIS" children={`Rp ${priceFormatter(totalTripFare)}`} style={{ fontWeight: 'bold', fontSize: "14px", color: "#0047A9" }} />
                    </Grid>
                    <Grid item xs={4}>
                        <Button id={"Pesan"} fullWidth children={"Pesan Tiket"} variant={"contained"} className={"submitButton"}
                            onClick={() => handlePesanSubmit()}
                        />
                    </Grid>
                </Grid>
                <ConfirmPopUp slideOpen={confirmBookingPopUpOpen}
                    totalTripFare={totalTripFare}
                    newTotalTripFare={confirmBookingInfo.newTotalTripFare}
                    handleConfirmBooking={handleConfirmBooking}
                    handleCancleConfirmBooking={handleCancleConfirmBooking} />

            </div>
            {isLoadingDialogue &&
                <RedirectPage>
                </RedirectPage>}
            <LoaderComponent slideOpen={loadingPopUp} />
            <FailedPopUp handleCancleConfirmBooking={handleCancleConfirmBooking} slideOpen={failedStatePopUp} />
        </div>
    )
}
export default InputData;



const PricingDetails = ({ flight, totalFare, initialFormData, flightDataOneWay, flightDataRound, baggagePrice }) => {
    const adultCount = initialFormData.passengers.adult
    const childCount = initialFormData.passengers.child
    const infantCount = initialFormData.passengers.infant

    const depAdultFare = parseFloatToDecimals(flightDataOneWay.journeys[0].fareInfo.partnerFare?.adultFare?.totalFareWithCurrency?.amount || 0);
    const depChildFare = parseFloatToDecimals(flightDataOneWay.journeys[0].fareInfo.partnerFare?.childFare?.totalFareWithCurrency?.amount || 0);
    const depInfantFare = parseFloatToDecimals(flightDataOneWay.journeys[0].fareInfo.partnerFare?.infantFare?.totalFareWithCurrency?.amount || 0);

    const arrAdultFare = parseFloatToDecimals(flightDataRound?.journeys[0].fareInfo.partnerFare?.adultFare?.totalFareWithCurrency?.amount || 0);
    const arrChildFare = parseFloatToDecimals(flightDataRound?.journeys[0].fareInfo.partnerFare?.childFare?.totalFareWithCurrency?.amount || 0);
    const arrInfantFare = parseFloatToDecimals(flightDataRound?.journeys[0].fareInfo.partnerFare?.infantFare?.totalFareWithCurrency?.amount || 0);

    return (
        <div style={{ margin: "24px 0 0 0" }} >
            <div style={{ marginTop: "16px" }} >
                <Grid container spacing={1} >
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                        <Typography id="Argo-Dewasa" children={`${flight?.dep?.airlineName} (Dewasa) x${adultCount}`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                        <Typography id="Argo-Rp" children={`Rp ${priceFormatter(depAdultFare * adultCount)}`} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                    </Grid>
                    {childCount != 0 && <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                        <Typography id="Argo-Dewasa" children={`${flight?.dep?.airlineName} (Anak) x${childCount}`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                        <Typography id="Argo-Rp" children={`Rp ${priceFormatter(depChildFare * childCount)}`} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                    </Grid>}
                    {infantCount != 0 && <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                        <Typography id="Argo-Dewasa" children={`${flight?.dep?.airlineName} (Bayi) x${infantCount}`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                        <Typography id="Argo-Rp" children={`Rp ${priceFormatter(depInfantFare * infantCount)}`} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                    </Grid>}

                    {flight?.ret && <>
                        <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <Typography id="Argo-Dewasa" children={`${flight?.ret?.airlineName} (Dewasa) x${adultCount}`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                            <Typography id="Argo-Rp" children={`Rp ${priceFormatter(arrAdultFare * adultCount)}`} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                        </Grid>
                        {childCount != 0 && <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <Typography id="Argo-Dewasa" children={`${flight?.ret?.airlineName} (Anak) x${childCount}`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                            <Typography id="Argo-Rp" children={`Rp ${priceFormatter(arrChildFare * childCount)}`} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                        </Grid>}
                        {infantCount != 0 && <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <Typography id="Argo-Dewasa" children={`${flight?.ret?.airlineName} (Bayi) x${infantCount}`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                            <Typography id="Argo-Rp" children={`Rp ${priceFormatter(arrInfantFare * infantCount)}`} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                        </Grid>}

                    </>}
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                        <Typography children={`Bagasi`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                        <Typography children={`Rp ${priceFormatter(baggagePrice)}`} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                    </Grid>

                    <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                        <Typography id="Argo-Bayi" children={`PPN`} style={{ fontWeight: "500", fontSize: "14px", color: "#615A5A" }} />
                        <Typography id="GRATIS" children={"Gratis"} style={{ fontWeight: "400", fontSize: "12px", color: "#1A1A1A" }} />
                    </Grid>
                </Grid>


            </div>
        </div>
    )
}