/* eslint-disable */
import React, { useState } from "react";
import { Button, IconButton, Paper, Slide, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GroupIcon from "@mui/icons-material/Group";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import PassengerSelectionField from "../../CommonComponents/PassengerSelectionField/PassengerSelectionField";

const SelectPassengerPopUp = ({
  slideOpen,
  setOpenPassenger,
  setPassengers,
  passengers,
}) => {
  const minPassengerCounts = {
    adults: 1,
    child: 0,
    infant: 0,
  };

  const [adults, setAdults] = useState(passengers.adults || 1);
  const [child, setChild] = useState(passengers.child || 0);
  const [infant, setInfant] = useState(passengers.infant || 0);
  const [adultsError, setAdultsError] = useState("");
  const [childError, setChildError] = useState("");
  const [infantError, setInfantError] = useState("");
  const [passengerError, setPassengerError] = useState("");

  const handleOnchangeAdults = (op) => {
    switch (op) {
      case "add":
        if (adults + child < 7) {
          setAdults(adults + 1);
        } else {
          setAdultsError("Penumpang Dewasa dan Anak tidak boleh melebihi 7");
          setTimeout(() => {
            setAdultsError("");
          }, 5000);
        }
        break;
      case "sub":
        if (adults > 1) {
          setAdults(adults - 1);
        }
        break;
      default:
        break;
    }
  };

  const handleOnchangeChild = (op) => {
    switch (op) {
      case "add":
        if (child + infant < adults && child + adults < 7) {
          setChild(child + 1);
        } else if (child + adults == 7) {
          setChildError("Penumpang Dewasa dan Anak tidak boleh melebihi 7");
          setTimeout(() => {
            setChildError("");
          }, 5000);
        } else {
          setChildError(
            "Jumlah penumpang anak dan bayi tidak boleh lebih dari penumpang dewasa"
          );
          setTimeout(() => {
            setChildError("");
          }, 5000);
        }
        break;
      case "sub":
        if (child != 0) {
          setChild(child - 1);
        }
        break;
      default:
        break;
    }
  };

  const handleOnchangeInfant = (op) => {
    switch (op) {
      case "add":
        if (child + infant < adults && infant < 4) {
          setInfant(infant + 1);
        } else if (infant == 4) {
          setInfantError("Penumpang bayi tidak boleh lebih dari 4");
          setTimeout(() => {
            setInfantError("");
          }, 5000);
        } else {
          setInfantError(
            "Jumlah penumpang anak dan bayi tidak boleh lebih dari penumpang dewasa"
          );
          setTimeout(() => {
            setInfantError("");
          }, 5000);
        }
        break;
      case "sub":
        if (infant != 0) {
          setInfant(infant - 1);
        }
        break;
      default:
        break;
    }
  };

  const handleOnSubmit = () => {
    if (adults + child <= 7 && child + infant <= adults && infant <= 4) {
      setPassengers({ adults, child, infant });
      setOpenPassenger(false);
    } else {
      if (adults + child > 7) {
        setPassengerError("Penumpang Dewasa dan Anak tidak boleh melebihi 7");
      } else if (infant > 4) {
        setPassengerError("Infant cannot be greater than 4");
      } else if (child + infant > adults) {
        setPassengerError(
          "Children and infant cannot be greater than adults. Please change."
        );
      }
      setTimeout(() => {
        setPassengerError("");
      }, 5000);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        background: "rgba(0,0,0,0.5)",
        display: slideOpen ? "" : "none",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "0",
          right: "0",
          left: "0",
          margin: "auto",
          maxWidth: "640px",
          borderRadius: "16px 16px 0 0",
          background: "#FFF",
          minHeight: "400px",
          display: slideOpen ? "" : "none",
        }}
      >
        <Slide
          direction={"up"}
          in={slideOpen}
          className={"sliderStationList"}
          id="slider-container"
        >
          <Paper
            id="slider-paper"
            style={{ height: slideOpen ? "100%" : "0%" }}
          >
            <div style={{ padding: "16px", paddingTop: "0" }}>
              <div className={"selectStationSliderHeader"}>
                <Typography
                  children={"Penumpang"}
                  className="popUpHeaderText"
                  id="headerText"
                />
                <IconButton
                  onClick={() => {
                    setOpenPassenger(false);
                  }}
                >
                  {" "}
                  <CloseIcon style={{ color: "#ADA6AE" }} />{" "}
                </IconButton>
              </div>

              <div className={"stationlistContainer"}>
                <PassengerSelectionField
                  passengerError={adultsError}
                  fieldIcon={<GroupIcon className="passengerIcons" />}
                  passenger={adults}
                  label={"Dewasa"}
                  subLabel={"Usia 12 tahun ke atas"}
                  passengerType={"adults"}
                  minPassengerCounts={minPassengerCounts}
                  OnChangePassengerNumber={handleOnchangeAdults}
                />
                <PassengerSelectionField
                  passengerError={childError}
                  fieldIcon={
                    <EscalatorWarningIcon className="passengerIcons" />
                  }
                  label={"Anak"}
                  subLabel={"Usia 2 -12 tahun"}
                  passenger={child}
                  passengerType={"child"}
                  minPassengerCounts={minPassengerCounts}
                  OnChangePassengerNumber={handleOnchangeChild}
                />
                <PassengerSelectionField
                  passengerError={infantError}
                  fieldIcon={
                    <EscalatorWarningIcon className="passengerIcons" />
                  }
                  label={"Bayi"}
                  subLabel={"Dibawah usia 2 tahun"}
                  passenger={infant}
                  passengerType={"infant"}
                  minPassengerCounts={minPassengerCounts}
                  OnChangePassengerNumber={handleOnchangeInfant}
                />
                <div>
                  <Typography
                    style={{
                      display: passengerError ? "" : "none",
                      color: "#d32f2f",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {passengerError}
                  </Typography>
                </div>
                <Button
                  children={"Terapkan"}
                  fullWidth
                  variant={"text"}
                  className={"submitButton"}
                  onClick={() => {
                    handleOnSubmit();
                  }}
                  id="CariTiketPesawat"
                />
              </div>
            </div>
          </Paper>
        </Slide>
      </div>
    </div>
  );
};

export default SelectPassengerPopUp;
