import { Typography } from "@mui/material";
import React from "react";

const BaggageSummaryItem = ({summary}) => {
  return (
    <div
      className="bag-select-summary-item"
      
    >
      <div>
        <Typography className="label-2">{summary.depAirport}</Typography>&nbsp;-&nbsp;
        <Typography className="label-2">{summary.arrAirport}</Typography>
      </div>
      <div><Typography className="label-3">Rp {summary.price}</Typography></div>
    </div>
  );
};

export default BaggageSummaryItem;
