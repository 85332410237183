/* eslint-disable */
import React from 'react';

import { CircularProgress, Paper, Slide, Typography } from '@mui/material';


const LoadingPopUp = ({ slideOpen }) => {
    
    return (
        <div style={{position: 'fixed', top:"0", bottom:"0", right:"0", left:"0", background:"#80808078", display: slideOpen ? "" : "none"}} >
        <div style={{position:"absolute", bottom:"0", right:"0", left:"0", minHeight: "240px", margin: "auto", maxWidth:"640px", borderRadius:"16px 16px 0 0", background:"#FFF", display: slideOpen ? "" : "none"}}>
            <Slide direction={"up"} in={slideOpen} className={"sliderStationList"} id='slider-container' >
                <Paper id="slider-paper" style={{height: slideOpen ? "100%" : "0%"}} >
                    <div style={{padding:"16px", paddingTop:"0"}} >

                        

                        <div className={"loaderHeader"}>
                            <Typography children={"Sedang memproses..."} className='popUpHeaderText' id="headerText" />
                        </div>

                        <div className={"loaderContainer"}>
                            <Typography>Proses ini dapat berlangsung beberapa menit.</Typography>
                            <Typography>Tunggu sebentar, ya.</Typography>
                        </div>
                        <div className='spinnerComponent'>
                            <CircularProgress style={{color: '#0047A9'}} />
                        </div>
                        
                    </div>
                </Paper>
            </Slide>
        </div>
        </div>
    )
}

export default LoadingPopUp;