/* eslint-disable */
import React from 'react';

import { FormControlLabel, IconButton, Paper, Slide, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';


const SortPopUP = ({ slideOpen,options, setOpenSortOptions, setSortValue, sortValue }) => {
    const radioButtonStyle = {
        color: "#0047A9",
        "&.Mui-checked": {
          color: "#0047A9",
        },
      };
    return (
        <div style={{position: 'fixed', top:"0", bottom:"0", right:"0", left:"0", background:"#80808078", display: slideOpen ? "" : "none"}} >
        <div style={{
            position:"absolute", 
            bottom:"0", right:"0", left:"0",
            margin: "auto", 
            maxWidth:"640px", 
            borderRadius:"16px 16px 0 0", 
            background:"#FFF", 
            display: slideOpen ? "" : "none",
            minHeight: "500px"
        }}>
            <Slide direction={"up"} in={slideOpen} className={"sliderStationList"} id='slider-container' >
                <Paper id="slider-paper" style={{height: slideOpen ? "100%" : "0%"}} >
                    <div style={{padding:"16px", paddingTop:"0"}} >

                        
                        <div className={"selectStationSliderHeader"}>
                            <Typography children={"Urutkan"} className='popUpHeaderText' id="headerText" />
                            <IconButton onClick={()=>{ setOpenSortOptions(false);}}> <CloseIcon style={{color:"#ADA6AE"}}/> </IconButton>
                        </div>

                        <div className={"stationlistContainer"}>
                            <FormControl style={{width: '100%'}}>
                                <RadioGroup value={sortValue} onChange={(e) => {
                                    setSortValue(e.target.value);
                                    setOpenSortOptions(false);
                                }}>   
                                    {
                                        options.map((option) => {
                                            return (
                                                <FormControlLabel
                                                    key={option.value} 
                                                    className={"classOptions"}
                                                    value={option.value} 
                                                    control={<Radio sx={radioButtonStyle} />} 
                                                    label={option.label} />
                                            )
                                        })
                                    }
                                    <div className='passengerBlock'></div>
                                </RadioGroup>
                            </FormControl>
                        </div>

                    </div>
                </Paper>
            </Slide>
        </div>
        </div>
    )
}

export default SortPopUP;