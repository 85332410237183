export default function Polling(delay, limitCnt){
    let cnt = 0;

    const poll = async (fn, exitConditionFn) => {
        const result = await fn(cnt);
        if(!exitConditionFn(result) && cnt < limitCnt){
            cnt++;
            await sleep(delay);
            return poll(fn, exitConditionFn);
        }
        return result;
    }


    return { poll };
} 

const sleep = (time) => {
    return new Promise((res) => {
        setTimeout(() => res(), time);
    });
}