/* eslint-disable */
import React, { useState } from "react";
import { Typography } from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { PopUpModal } from "../PopUp/PopUpModal";
import { Link } from "react-router-dom";

const Header = ({ header, back }) => {
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });

  const handlePopUpClose = () => {
    showErrorPopUp({ showPopUp: false });
  };

  return (
    <div id="header" className="header">
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />
      <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
        {back != "" ? (
          <Link to={back}>
            <ArrowBackRoundedIcon
              style={{ color: "#333333", marginRight: "10px" }}
            />
          </Link>
        ) : (
          ""
        )}
        <Typography
          style={{ display: "flex", width: "100%" }}
          id="headerText"
          className="headerText"
        >
          {header}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
