import { TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

export default ({
  value,
  label,
  error,
  onFocus,
  onChange,
  onBlur,
  errorMessage,
  isFocused,
  type
}) => {
  const classes = useStyle();
  const isFocusedStyles = isFocused
    ? classes.selectDropDownFocused
    : classes.selectDropDown;
  return (
    <>
      <Typography className={classes.selectLabel} children={label} />
      <TextField
        type={type || "text"}
        id={"firstName-value"}
        fullWidth
        autoComplete={"off"}
        value={value}
        variant="standard"
        error={error}
        onFocus={onFocus}
        onBlur={onBlur}
        InputProps={{
          disableUnderline: true,
        }}
        inputProps={{
          className: error ? classes.selectDropDownError : isFocusedStyles
        }}
        helperText={errorMessage}
        onChange={onChange}
        FormHelperTextProps={{
          style: {
            margin: "0",
            color: "#c21616",
            display: error ? "" : "none",
          },
        }}
      />
    </>
  );
};

const useStyle = makeStyles({
  selectDropDown: {
    border: "1px solid #DDDDDD",
    borderRadius: "8px",
    padding: "10px",
    fontWeight: "bold",
    "& .MuiSelect-standard": {
      "&:focus": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  selectDropDownFocused: {
    border: "1px solid #1A1A1A",
    borderRadius: "8px",
    padding: "10px",
    fontWeight: "bold",
    "& .MuiSelect-standard": {
      "&:focus": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  selectDropDownError: {
    border: "1px solid #d32f2f",
    borderRadius: "8px",
    padding: "10px",
    "& .MuiSelect-standard": {
      "&:focus": {
        backgroundColor: "#FFFFFF",
      },
    },
  },
  selectLabel: {
    marginTop: "10px",
    color: "#515151",
    fontWeight: "bold",
    fontSize: "14px",
  },
});
