import { useCallback } from "react";

export const APP_LOCAL_STORAGE_KEYS = {
    airportsByCode:"AIRPORTS_BY_CODE",
    airports:"AIRPORTS",
    initialFormData:"INITIAL_FORM_DATA",
    flightSearchResultsOneWay:"FLIGHT_SEARCH_RESULTS_ONE_WAY",
    flightSearchResultsRound:"FLIGHT_SEARCH_RESULTS_ROUND",
    selectedFlightDetailsOneWay:"SELECTED_FLIGHT_DETAILS_ONE_WAY",
    selectedFlightDetailsRound:"SELECTED_FLIGHT_DETAILS_ROUND",
    airlines:"AIRLINES",
    flightTypeForListing:"FLIGHT_TYPE_FOR_LISTING",
    baggageSelection:"BAGGAGE_SELECTION"
}

export const useLocalStorage = () => {
    const set = useCallback((key, value) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log(error);
        }
    },[]);

    const get = useCallback((key) => {
        try {
            const stringifiedValue = window.localStorage.getItem(key);
            return JSON.parse(stringifiedValue);
        } catch (error) {
            console.log(error);
        }
    },[]);
    return {set, get};
};

export default useLocalStorage;